import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 34 33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M17.393 8.113a2.887 2.887 0 1 1 0 5.774 2.887 2.887 0 0 1 0-5.774Zm0 12.375c4.083 0 8.387 2.007 8.387 2.887v1.512H9.005v-1.512c0-.88 4.304-2.887 8.388-2.887Zm0-14.988a5.498 5.498 0 0 0-5.5 5.5c0 3.039 2.46 5.5 5.5 5.5 3.038 0 5.5-2.461 5.5-5.5s-2.462-5.5-5.5-5.5Zm0 12.375c-3.672 0-11 1.843-11 5.5V27.5h22v-4.125c0-3.657-7.33-5.5-11-5.5Z"
    }, null, -1)
  ])))
}
export default { render: render }